import "./App.css";
import { GDTRegister } from "./ui-components";

import { DataStore } from "@aws-amplify/datastore";
import { Gdt } from "./models";
// import { withAuthenticator } from "@aws-amplify/ui-react";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <GDTRegister style={{ textAlign: "left" }} />
      </header>
    </div>
  );
}

export default App;
