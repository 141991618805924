/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Flex,
  Grid,
  SwitchField,
  TextField,
} from "@aws-amplify/ui-react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Gdt } from "../models";
import { fetchByPath, validateField } from "./utils";
import { DataStore } from "aws-amplify";
export default function GDTRegister(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onCancel,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    name: "",
    age: "",
    phone: "",
    class_1: false,
    class_2: false,
    class_3: false,
  };
  const [name, setName] = React.useState(initialValues.name);
  const [age, setAge] = React.useState(initialValues.age);
  const [phone, setPhone] = React.useState(initialValues.phone);
  const [class_1, setClass_1] = React.useState(initialValues.class_1);
  const [class_2, setClass_2] = React.useState(initialValues.class_2);
  const [class_3, setClass_3] = React.useState(initialValues.class_3);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setName(initialValues.name);
    setAge(initialValues.age);
    setPhone(initialValues.phone);
    setClass_1(initialValues.class_1);
    setClass_2(initialValues.class_2);
    setClass_3(initialValues.class_3);
    setErrors({});
  };
  const validations = {
    name: [{ type: "Required" }],
    age: [{ type: "Required" }],
    phone: [{ type: "Required" }],
    class_1: [],
    class_2: [],
    class_3: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value = getDisplayValue
      ? getDisplayValue(currentValue)
      : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          name,
          age,
          phone,
          class_1,
          class_2,
          class_3,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value.trim() === "") {
              modelFields[key] = undefined;
            }
          });
          await DataStore.save(new Gdt(modelFields));
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "GDTRegister")}
      {...rest}
    >
      <TextField
        label="Student's Name 名"
        isRequired={true}
        isReadOnly={false}
        value={name}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name: value,
              age,
              phone,
              class_1,
              class_2,
              class_3,
            };
            const result = onChange(modelFields);
            value = result?.name ?? value;
          }
          if (errors.name?.hasError) {
            runValidationTasks("name", value);
          }
          setName(value);
        }}
        onBlur={() => runValidationTasks("name", name)}
        errorMessage={errors.name?.errorMessage}
        hasError={errors.name?.hasError}
        {...getOverrideProps(overrides, "name")}
      ></TextField>
      <TextField
        label="Student's age 岁"
        isRequired={true}
        isReadOnly={false}
        type="number"
        step="any"
        value={age}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              name,
              age: value,
              phone,
              class_1,
              class_2,
              class_3,
            };
            const result = onChange(modelFields);
            value = result?.age ?? value;
          }
          if (errors.age?.hasError) {
            runValidationTasks("age", value);
          }
          setAge(value);
        }}
        onBlur={() => runValidationTasks("age", age)}
        errorMessage={errors.age?.errorMessage}
        hasError={errors.age?.hasError}
        {...getOverrideProps(overrides, "age")}
      ></TextField>
      <TextField
        label="Phone 电话号码"
        isRequired={true}
        isReadOnly={false}
        value={phone}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              age,
              phone: value,
              class_1,
              class_2,
              class_3,
            };
            const result = onChange(modelFields);
            value = result?.phone ?? value;
          }
          if (errors.phone?.hasError) {
            runValidationTasks("phone", value);
          }
          setPhone(value);
        }}
        onBlur={() => runValidationTasks("phone", phone)}
        errorMessage={errors.phone?.errorMessage}
        hasError={errors.phone?.hasError}
        {...getOverrideProps(overrides, "phone")}
      ></TextField>
      <SwitchField
        label="Cooking Class"
        defaultChecked={false}
        isDisabled={false}
        isChecked={class_1}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              name,
              age,
              phone,
              class_1: value,
              class_2,
              class_3,
            };
            const result = onChange(modelFields);
            value = result?.class_1 ?? value;
          }
          if (errors.class_1?.hasError) {
            runValidationTasks("class_1", value);
          }
          setClass_1(value);
        }}
        onBlur={() => runValidationTasks("class_1", class_1)}
        errorMessage={errors.class_1?.errorMessage}
        hasError={errors.class_1?.hasError}
        {...getOverrideProps(overrides, "class_1")}
      ></SwitchField>
      <SwitchField
        label="Nature hike"
        defaultChecked={false}
        isDisabled={false}
        isChecked={class_2}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              name,
              age,
              phone,
              class_1,
              class_2: value,
              class_3,
            };
            const result = onChange(modelFields);
            value = result?.class_2 ?? value;
          }
          if (errors.class_2?.hasError) {
            runValidationTasks("class_2", value);
          }
          setClass_2(value);
        }}
        onBlur={() => runValidationTasks("class_2", class_2)}
        errorMessage={errors.class_2?.errorMessage}
        hasError={errors.class_2?.hasError}
        {...getOverrideProps(overrides, "class_2")}
      ></SwitchField>
      <SwitchField
        label="Science with English"
        defaultChecked={false}
        isDisabled={false}
        isChecked={class_3}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              name,
              age,
              phone,
              class_1,
              class_2,
              class_3: value,
            };
            const result = onChange(modelFields);
            value = result?.class_3 ?? value;
          }
          if (errors.class_3?.hasError) {
            runValidationTasks("class_3", value);
          }
          setClass_3(value);
        }}
        onBlur={() => runValidationTasks("class_3", class_3)}
        errorMessage={errors.class_3?.errorMessage}
        hasError={errors.class_3?.hasError}
        {...getOverrideProps(overrides, "class_3")}
      ></SwitchField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Cancel"
            type="button"
            onClick={() => {
              onCancel && onCancel();
            }}
            {...getOverrideProps(overrides, "CancelButton")}
          ></Button>
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
